import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Footer from '../components/Footer';
import Header from '../components/Header';
import { UserContext } from '../contexts/user-context';
import { createUserWithEmail } from '../Firebase';
import Completed from './completed';

export default function Claim() {
  const { user, patchUser } = useContext(UserContext);
  const [email, setEmail] = useState('');
  const [hasAgreed, setHasAgreed] = useState(false);
  const navigate = useNavigate();
  const [error, setError] = useState<string | null>();

  const submitEmail = async () => {
    if (!hasAgreed || !email.match(/\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+.[A-Za-z]{2,}\b/g)) {
      return;
    }
    try {
      const userCredentials = await createUserWithEmail(email)
      patchUser({
        email: userCredentials.user.email,
      });
      navigate('/completed');
    } catch(err: any) {
      if('message' in err) {
        if(err.message.includes('email-already-in-use')) {
          setError("Diese Email wurde bereits verwendet.");
          return;
        }
      }
      setError("Bei ihrer Anfrage is ein Fehler aufgetreten.");
    }
  };

  if (user && user?.email !== null) {
    return <Completed />;
  }

  return (
    <>
      <Header
        title='Glückwunsch!!'
        description='Sie haben eine 20% Ermäßigung auf ein Ticket für die nächste Saison gewonnen!'
        backLink='/home'
      />
      <main className='flex flex-col gap-1 justify-start text-center flex-grow'>
        <img className='mx-auto' src='/ticket-large.png' width='300' height='213' alt='Ratiopharm Ulm Logo'></img>
        <h3 className='primary-font'>Wenn Sie das Ergebnis richtig erraten haben, gewinnen Sie außerdem eine mysteriöse Belohnung!</h3>
        <div className='ui-fg py-5 px-7 flex flex-col gap-3 mt-auto'>
          <h3 className='primary-font'>Ihre Prämie wird Ihnen per E-Mail zugesandt:</h3>
          <input
            type='email'
            placeholder='Ihre Email'
            className='rounded-[10px] border-transparent p-2 w-[100%]'
            defaultValue={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <div className='flex gap-3 px-3'>
            <input type='checkbox' onChange={(e) => setHasAgreed(e.target.checked)} />
            <label className='text-start'>
              <a href={`/terms?returnUrl=${encodeURIComponent('/claim')}`} className='text-[14px] underline'>
                Ich habe die Datenschutzbestimmungen gelesen und stimme ihnen zu.
              </a>
            </label>
          </div>
          {error && <p className='text-red-600'>{error}</p>}
        </div>
      </main>
      <Footer
        onClick={submitEmail}
        disabled={!hasAgreed || !email.match(/\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+.[A-Za-z]{2,}\b/g)}
        label='SENDEN'
      />
    </>
  );
}

export {};
