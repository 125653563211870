type FooterProps = {
  onClick?: (event: any) => void;
  disabled?: boolean;
  label?: string
}

export default function Footer({onClick, disabled = false, label = 'BESTÄTIGEN'}: FooterProps) {
  return <footer className='flex justify-center w-100 py-8 mt-auto ui-fg'>
  <button onClick={onClick} disabled={disabled}>
    {label}
  </button>
</footer>
}
export {}
