import { FormEvent, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Footer from '../components/Footer';
import Header from '../components/Header';
import { UserContext } from '../contexts/user-context';
import useDebounce from '../hooks/useDebounce';
import { User } from '../types/user';
import Completed from './completed';

export default function Prediction() {
  const { user, patchUser, setUser } = useContext(UserContext);
  const [result, setResult] = useState<number | null>(null);
  const debouncedResult = useDebounce(result, 500);
  const navigate = useNavigate();

  useEffect(() => {
    if (Boolean(user?.prediction.submitted)) {
      return;
    }
    if (!debouncedResult) {
      return;
    }
    setUser((user: User) => ({...user, prediction: {result: debouncedResult, submitted: user?.prediction.submitted}}));
  }, [debouncedResult]);

  const submitPrediction = async () => {
    if (!result && !user?.prediction.result) {
      return;
    }
    await patchUser({
      prediction: {
        result: result ?? user?.prediction.result,
        submitted: true,
      },
      predictionSubmitted: new Date(),
    });
    navigate('/home');
  };

  const filterNumbers = (e: FormEvent<HTMLInputElement>) => {
    e.preventDefault();
    let numberString = removeLeadingZeros(e.currentTarget.value);
    if(numberString.length > 3) {
      numberString = numberString.slice(0, 3);
    }
    e.currentTarget.value = fillLeadingZeros(numberString);
    setResult(parseInt(numberString));
  }

  const removeLeadingZeros = (str: string) => {
    const characters = str.split('');
    // remove leading zeros
    let charactersToRemove = 0;
    for(let i = 0; i < characters.length; i++) {
      if(characters[i] === '0') {
        charactersToRemove++;
      } else {
        break;
      }
    }
    characters.splice(0, charactersToRemove);
    return characters.join('');
  }

  const fillLeadingZeros = (numberString: string) => {
    if(numberString.length < 3) {
      const zeros: string[] = new Array<string>(3 - numberString.length);
      for(let i = 0; i < zeros.length; i++) {
        zeros[i] = '0';
      }
      numberString = zeros.join('') + numberString;
    }
    return numberString;
  }

  if (user && user?.email !== null) {
    return <Completed />;
  }

  return (
    <>
      <Header
        title='Punktestand'
        description='Tippen Sie den Punktestand zu Ende des Spiels und erhalten Sie eine zusätzliche Belohnung. Sie können Ihre Wahl bis zur Halbzeitpause bestätigen!'
        backLink='/home'
      />
      <main className='flex flex-col gap-1 justify-start text-center'>
        <div className='ui-fg w-100 px-5 py-7 flex flex-col gap-3'>
          <div className='flex flex-col gap-5 p-7 ui-bg border items-center rounded-[15px] border-transparent w-[100%]'>
            <img className='mx-auto' src='/ratiopharm-ulm.png' width='126' height='195' alt='Ratiopharm Ulm Logo'></img>
            <h1>ratiopharm ulm</h1>
          </div>
          <p className='primary-font p-3'>Der Punktestand für Ulm bei Spielende: </p>
          <div className="relative">
            <div className="absolute inset-0 tracking-[15px] grid place-items-center ps-4 text-[25px] pointer-events-none pb-1 font-light">
              | |
            </div>
            <input
              type='number'
              className='self-center text-right tracking-[30px] '
              defaultValue={user?.prediction.result}
              onInput={filterNumbers}
            />
          </div>
        </div>
      </main>
      <Footer onClick={submitPrediction} disabled={!result && !user?.prediction.result} />
    </>
  );
}

export {};
