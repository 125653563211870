'use client';

import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Footer from '../components/Footer';
import Header from '../components/Header';
import RewardItem from '../components/RewardItem';
import { UserContext } from '../contexts/user-context';
import { User } from '../types/user';
import Completed from './completed';

export default function Rewards() {
  const { user, patchUser, setUser } = useContext(UserContext);
  const navigate = useNavigate();

  const chosenRewardCount = user?.rewards.rewardList.filter(r => r.priority > 0).length ?? 0;

  const submitChoices = async () => {
    await patchUser({
      rewards: {
        rewardList: user?.rewards.rewardList,
        submitted: true,
      },
      rewardsSubmitted: new Date(),
    });
    navigate('/home');
  };

  const setPriority = (index: number) => {
    if (chosenRewardCount > 3) {
      return;
    }
    setUser((current: User) => ({...current, rewards: {
        ...current?.rewards,
        rewardList: user?.rewards.rewardList.map((reward) => {
          if (reward.index === index) {
            reward.priority = chosenRewardCount + 1;
            return reward;
          }
          return reward;
        }),
        submitted: current?.rewards.submitted,
      }
    }));
  };

  const removePriority = (index: number) => {
    const reward = user?.rewards.rewardList.find((r) => r.index === index) ?? null;
    if (reward !== null && reward.priority > 0) {
      const removedPriority = reward.priority;
      reward.priority = 0;
      patchUser({
        rewards: {
          ...user?.rewards,
          rewardList: user?.rewards.rewardList.map((reward) => ({
            ...reward,
            priority: reward.priority > 0 && reward.priority > removedPriority ? reward.priority - 1 : reward.priority,
          })),
        },
      });
      return;
    }
  };

  if (user && user?.email !== null) {
    return <Completed />;
  }

  return (
    <>
      <Header
        title='Gib mir mehr'
        description='Gestalte die Zukunft der Fanbelohnungen! Wähle bis zu 4 Optionen aus, die dich ansprechen. Deine Stimme zählt!'
        backLink='/home'
      />
      <main className='flex flex-col gap-1 justify-start text-center'>
        <p className='self-end primary-font py-1 px-5'>{chosenRewardCount}/4 ausgewählt</p>
        {user?.rewards.rewardList.map((reward) => (
          <RewardItem key={reward.title} item={reward} removePriority={removePriority} setPriority={setPriority} />
        ))}
      </main>
      <Footer disabled={chosenRewardCount < 4} onClick={submitChoices} />
    </>
  );
}
export {};
