import { useSearchParams } from 'react-router-dom';
import Header from '../components/Header';

export default function Completed() {
  const [searchParams] = useSearchParams();
  return (
    <>
      <Header title='Hilfe' description='Help me please' backLink={searchParams.get('returnUrl') ?? '/'} />
      <main className='flex flex-col gap-1 justify-start text-center flex-grow'>
        <p className='p-5'>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Assumenda tempore inventore ea tempora exercitationem illo beatae, omnis
          quaerat similique numquam excepturi quidem! Architecto repellendus ab aliquid est magnam, ad sed quis? Fugiat atque, doloremque
          magnam rem voluptatibus excepturi aspernatur velit accusamus temporibus consectetur fuga nesciunt ipsum animi sequi neque hic
          nostrum, possimus impedit quibusdam voluptatem!{' '}
        </p>
        <p className='p-5'>
          Corporis repudiandae labore ducimus cumque atque! Quia iste, commodi earum cumque officia est animi dolores. Aspernatur asperiores
          est voluptate nemo, qui nobis sed impedit incidunt sint consectetur numquam rem pariatur quae, sunt mollitia! Veritatis quo fuga
          pariatur ex sunt, recusandae illo ea error eum sit!
        </p>
      </main>
    </>
  );
}

export {};
