import { addDoc, collection, doc, getDoc, setDoc } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { firestore } from '../Firebase';
import { Reward } from '../types/reward';
import { User } from '../types/user';
import shuffleArray from '../util/shuffleArray';

const rewardList = [
  {
    index: 1,
    title: 'Eintrittskarten',
    description: 'Rabatte und Angebote für kommende Spiele.',
    image: 'ticket.png',
    priority: 0,
  },
  {
    index: 2,
    title: 'Meet & Greet',
    description: 'Triff deine Lieblingsspieler persönlich.',
    image: 'handshake.png',
    priority: 0,
  },
  {
    index: 3,
    title: 'VIP-Karten',
    description: 'Exklusiver Zugang zur VIP-Lounge für das ultimative Erlebnis.',
    image: 'vip-ticket.png',
    priority: 0,
  },
  {
    index: 4,
    title: 'Speisen und Getränke',
    description: 'Genieße das Spiel mit besonderen Angeboten im Restaurant.',
    image: 'beer.png',
    priority: 0,
  },
  {
    index: 5,
    title: 'Merchandise-Rabatte',
    description: 'Angebote für unsere offizielle Merch-Kollektion.',
    image: 'reduced-merch.png',
    priority: 0,
  },
  {
    index: 6,
    title: 'Einzigartige Merchandise-Artikel',
    description: 'Limitierte Auflagen mit Autogrammen.',
    image: 'exclusive-merch.png',
    priority: 0,
  },
  {
    index: 7,
    title: 'Kollaboration',
    description: 'Die Chance, einzigartige Merchandise-Artikel und limitierte Editionen mitzugestalten.',
    image: 'collab-merch.png',
    priority: 0,
  },
  {
    index: 8,
    title: 'Handelbare Sammlerstücke',
    description: 'Du kannst exklusive Artikel besitzen und tauschen.',
    image: 'tradeables.png',
    priority: 0,
  },
];

export default function useUser(): {
  user: User;
  patchUser: (delta: Partial<Omit<User, 'id'>>) => Promise<void>;
  setUser: React.Dispatch<React.SetStateAction<User>>;
  isLoading: boolean;
  error: any;
} {
  const [user, setUser] = useState<User>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const getUser = async () => {
      try {
        const id = localStorage.getItem('id') ?? null;
        if (id === null) {
          const group = Math.random() < 0.5 ? 'A' : 'B';
          const docRef = await addDoc(collection(firestore, 'users'), {
            group,
            created: new Date(),
            prediction: {
              result: null,
              submitted: false,
            },
            predictionSubmitted: null,
            rewards: {
              rewardList: group === 'A' ? [...rewardList] : createRandomRewardArray(),
              submitted: false,
            },
            rewardsSubmitted: null,
            email: null,
          });
          const data = await getDoc(docRef);
          setUser({
            id: data.id,
            created: new Date(),
            email: data.get('email'),
            prediction: data.get('prediction'),
            rewards: data.get('rewards'),
            predictionSubmitted: null,
            rewardsSubmitted: null,
          });
          localStorage.setItem('id', data.id);
          return;
        }
        const userCollectionRef = doc(firestore, 'users', id);
        const data = await getDoc(userCollectionRef);
        setUser({
          id: data.id,
          created: data.get('created'),
          email: data.get('email'),
          prediction: data.get('prediction'),
          rewards: data.get('rewards'),
          predictionSubmitted: data.get('predictionSubmitted'),
          rewardsSubmitted: data.get('rewardsSubmitted'),
        });
        setIsLoading(false);
      } catch (err: any) {
        console.log(err);
        setIsLoading(false);
        setError(err);
      }
    };
    getUser();
  }, []);

  const patchUser = async (delta: Partial<User>): Promise<void> => {
    if (!user) {
      setError('User not found');
      return;
    }
    const oldUser = { ...user };
    try {
      const updatedUser: User = { ...user, ...delta };
      setUser((u) => updatedUser);
      const docRef = doc(firestore, 'users', user.id);
      await setDoc(docRef, { ...delta }, { merge: true });
    } catch (err) {
      setUser(oldUser);
    }
  };

  return { user, patchUser, setUser, isLoading, error };
}

function createRandomRewardArray(): Reward[] {
  return shuffleArray(rewardList).map((reward, index) => ({ ...reward, index: index }));
}
