import { useSearchParams } from 'react-router-dom';
import { applyActionCode, getAuth } from 'firebase/auth';
import { useEffect, useState } from 'react';
import Header from '../components/Header';

export default function VerifyEmail() {
  const [searchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(true);
  const [isVerified, setIsVerified] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const auth = getAuth();
    applyActionCode(auth, searchParams.get('oobCode') ?? '')
      .then(() => {
        setIsVerified(true);
      })
      .catch((err) => {
        setError(err);
        console.log(err);
      })
      .finally(() => setIsLoading(false));
  }, [searchParams]);

  return (
    <>
      <Header title='Email Bestätigung' />
      <main className='p-5 text-center'>
        {isLoading && <p>Ihre Email wird verifiziert...</p>}
        {isVerified && <p>Ihre Email wurde erfolgreich verifiziert.</p>}
        {error && <p> Bei der Verifizierung ihrer Email ist ein Fehler aufgetreten.</p>}
      </main>
    </>
  );
}
