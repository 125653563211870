import { useSearchParams } from 'react-router-dom';
import Header from '../components/Header';

export default function Terms() {
  const [searchParams] = useSearchParams();
  return (
    <>
      <Header backLink={searchParams.get('returnUrl') ?? '/'} title='Datenschutz'></Header>
      <main className='flex flex-col gap-1 justify-start flex-grow px-7 gap-3'>
        <h3 className='primary-font'>1. Veranstalter </h3>
        <p className='flex gap-2'>
          <span>(1)</span> Veranstalter des Gewinnspiels ist die Achtzig20 GmbH, vertreten durch die Geschäftsführung Florian Holste.
        </p>
        <h3 className='mt-3 primary-font'>2. Teilnahmebedingungen</h3>
        <p className='flex gap-2'>
          <span>(1)</span>
          Teilnehmer wird jede Person, die die Teilnahmebedingungen erfüllt. Dies bedeutet, dass bis zur Halbzeit eines Spiels des
          Basketballvereins Ratiopharm Ulm den Punktestand des jeweiligen Spiels getippt und eine E-Mail-Adresse angegeben wurde.{' '}
        </p>
        <p className='flex gap-2'>
          <span>(2)</span>
          Die Teilnahme an dem Gewinnspiel ist kostenlos.
        </p>
        <p className='flex gap-2'>
          <span>(3)</span>
          Zusätzlich kann man sich an der Umfrage zu potenziellen zukünftigen 4 Fanbelohnungen beteiligen. Diese Umfrage ist unabhängig von
          dem Gewinnspiel und dient zum Zweck der Informationsgewinnung über präferierte, potenzielle zukünftige Rewards.
        </p>
        <p className='flex gap-2'>
          <span>(4)</span>
          Juristische Personen sind von der Teilnahme ausgeschlossen, dementsprechend beschränkt sich das Gewinnspiel ausschließlich auf
          natürliche Personen. Eine mehrfach-Teilnahme einer natürlichen Person ist untersagt und führt zum Ausschluss vom Gewinnspiel. Für
          die Teilnahme muss das 14. Lebensjahr erreicht sein. Für Teilnehmer, welche das vollendete, 18. Lebensjahr nicht erreicht haben,
          setzt der Veranstalter eine Zustimmung der Erziehungsberechtigten voraus.
        </p>
        <p className='flex gap-2'>
          <span>(5)</span>
          Das Gewinnspiel startet mit dem Spiel von Ratiopharm Ulm. Die Teilnahme am Gewinnspiel, durch Abgabe eines Tipps in Bezug auf den
          Punktestand, ist bis zur Halbzeit des Spiels möglich. Später abgegebene Tipps werden nicht berücksichtigt. Die Durchführung der
          Umfrage kann auch nach Ende des Spiels erfolgen.
        </p>
        <p className='flex gap-2'>
          <span>(6)</span>
          Der Veranstalter hält sich vor die Teilnahmebedingungen zu verändern. Eine solche Veränderung wird öffentlich kommuniziert.
        </p>
        <p className='flex gap-2'>
          <span>(7)</span>
          Der Veranstalter behält es sich vor, im begründeten Verdachtsfall oder bei berechtigtem Interesse, Personen vom Gewinnspiel
          auszuschließen.
        </p>
        <p className='flex gap-2'>
          <span>(8)</span>
          Der Rechtsweg wird hiermit ausgeschlossen.
        </p>
        <h3 className='mt-3 primary-font'>3. Datenschutz</h3>
        <p className='flex gap-2'>
          <span>(1)</span>
          Für die Teilnahme am Gewinnspiel ist es notwendig, dass die teilnehmende Person personenbezogene Daten in Form einer
          E-Mail-Adresse angibt. Diese Angabe ist für die Durchführung des Gewinnspiels notwendig. Verarbeiter ist der Veranstalter des
          Gewinnspiels.
        </p>
        <p className='flex gap-2'>
          <span>(2)</span>
          Die angegebenen personenbezogenen Daten werden nur für den erwartbaren Zweck zur Erfüllung des Gewinnspiels genutzt.
        </p>
        <p className='flex gap-2'>
          <span>(3)</span>
          Die angegebenen personenbezogenen Daten werden nur für den Zeitraum der Erfüllung ihres Zweckes gespeichert und anschließend
          gelöscht.
        </p>
        <p className='flex gap-2'>
          <span>(4)</span>
          Die angegebenen personenbezogenen Daten werden nicht an dritte Weitergebegeben.
        </p>
        <p className='flex gap-2'>
          <span>(5)</span>
          Die Betroffenenrechte der teilnehmenden Person, sowie der Kontakt für Fragen zum Thema Datenschutz kann der Datenschutzerklärung
          des Veranstalters entnommen werden.
        </p>
      </main>
    </>
  );
}
