import { ReactElement } from 'react';
import { Link } from 'react-router-dom';

type HeaderProps = {
  title: string;
  description?: string;
  backLink?: string;
  helpLink?: string;
};

const Header = ({ title, description, backLink, helpLink }: HeaderProps): ReactElement => {
  return (
    <header className='px-3 pb-3'>
      <div className='p-2 flex justify-between items-center'>
        {backLink ? (
          <Link to={backLink}>
            <svg width='10' height='16' viewBox='0 0 10 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M7.34566 15.6834L0.232203 8.59103C0.14777 8.5066 0.087823 8.41513 0.0523613 8.31662C0.0168996 8.21812 -0.000549692 8.11258 1.31927e-05 8C1.31927e-05 7.88742 0.0174624 7.78188 0.0523613 7.68338C0.0872602 7.58487 0.147207 7.4934 0.232203 7.40897L7.34566 0.295514C7.54267 0.0985048 7.78893 0 8.08444 0C8.37996 0 8.63326 0.105541 8.84434 0.316623C9.05542 0.527704 9.16096 0.773967 9.16096 1.05541C9.16096 1.33685 9.05542 1.58311 8.84434 1.7942L2.63854 8L8.84434 14.2058C9.04135 14.4028 9.13985 14.6457 9.13985 14.9345C9.13985 15.2232 9.03431 15.4729 8.82323 15.6834C8.61215 15.8945 8.36589 16 8.08444 16C7.803 16 7.55674 15.8945 7.34566 15.6834Z'
                fill='#ec7404'
              />
            </svg>
          </Link>
        ) : (
          <div className='w-[10px]'></div>
        )}
        <h2 className='text-center'>{title}</h2>
        {helpLink ? (
          <Link to={helpLink}>
            <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M7.96 12.8C8.24 12.8 8.4768 12.7032 8.6704 12.5096C8.864 12.316 8.96053 12.0795 8.96 11.8C8.96 11.52 8.86347 11.2832 8.6704 11.0896C8.47733 10.896 8.24053 10.7995 7.96 10.8C7.68 10.8 7.44347 10.8968 7.2504 11.0904C7.05733 11.284 6.96053 11.5205 6.96 11.8C6.96 12.08 7.0568 12.3168 7.2504 12.5104C7.444 12.704 7.68053 12.8005 7.96 12.8ZM7.24 9.72H8.72C8.72 9.28 8.77013 8.93333 8.8704 8.68C8.97067 8.42667 9.25387 8.08 9.72 7.64C10.0667 7.29333 10.34 6.9632 10.54 6.6496C10.74 6.336 10.84 5.95947 10.84 5.52C10.84 4.77333 10.5667 4.2 10.02 3.8C9.47333 3.4 8.82667 3.2 8.08 3.2C7.32 3.2 6.7032 3.4 6.2296 3.8C5.756 4.2 5.42613 4.68 5.24 5.24L6.56 5.76C6.62667 5.52 6.7768 5.26 7.0104 4.98C7.244 4.7 7.60053 4.56 8.08 4.56C8.50667 4.56 8.82667 4.6768 9.04 4.9104C9.25333 5.144 9.36 5.40053 9.36 5.68C9.36 5.94667 9.28 6.1968 9.12 6.4304C8.96 6.664 8.76 6.88053 8.52 7.08C7.93333 7.6 7.57333 7.99333 7.44 8.26C7.30667 8.52667 7.24 9.01333 7.24 9.72ZM8 16C6.89333 16 5.85333 15.7899 4.88 15.3696C3.90667 14.9493 3.06 14.3795 2.34 13.66C1.62 12.94 1.05013 12.0933 0.6304 11.12C0.210667 10.1467 0.000533333 9.10667 0 8C0 6.89333 0.210133 5.85333 0.6304 4.88C1.05067 3.90667 1.62053 3.06 2.34 2.34C3.06 1.62 3.90667 1.05013 4.88 0.6304C5.85333 0.210667 6.89333 0.000533333 8 0C9.10667 0 10.1467 0.210133 11.12 0.6304C12.0933 1.05067 12.94 1.62053 13.66 2.34C14.38 3.06 14.9501 3.90667 15.3704 4.88C15.7907 5.85333 16.0005 6.89333 16 8C16 9.10667 15.7899 10.1467 15.3696 11.12C14.9493 12.0933 14.3795 12.94 13.66 13.66C12.94 14.38 12.0933 14.9501 11.12 15.3704C10.1467 15.7907 9.10667 16.0005 8 16ZM8 14.4C9.78667 14.4 11.3 13.78 12.54 12.54C13.78 11.3 14.4 9.78667 14.4 8C14.4 6.21333 13.78 4.7 12.54 3.46C11.3 2.22 9.78667 1.6 8 1.6C6.21333 1.6 4.7 2.22 3.46 3.46C2.22 4.7 1.6 6.21333 1.6 8C1.6 9.78667 2.22 11.3 3.46 12.54C4.7 13.78 6.21333 14.4 8 14.4Z'
                fill='#ec7404'
              />
            </svg>
          </Link>
        ) : (
          <div className='w-[10px]'></div>
        )}
      </div>

      {description && <p className='text-center px-5'>{description}</p>}
    </header>
  );
};

export default Header;
