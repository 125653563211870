import { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Footer from '../components/Footer';
import Header from '../components/Header';
import { TimeContext } from '../contexts/time-context';
import { UserContext } from '../contexts/user-context';
import Completed from './completed';

export default function Home() {
  const { user } = useContext(UserContext);
  const { isHalfTime, isLoading } = useContext(TimeContext);
  const navigate = useNavigate();
  const expired = !isLoading && isHalfTime();

  const completedTasks = (user?.prediction.submitted ? 1 : 0) + (user?.rewards.submitted ? 1 : 0);

  if (user && user?.email !== null) {
    return <Completed />;
  }

  return (
    <>
      <Header title='Mitmachen & Gewinnen' description='Aufgaben erfüllen. Belohnungen gewinnen. Ganz Einfach.' />
      <main className='flex flex-col gap-1 justify-start flex-grow'>
        <p className='self-end primary-font py-1 px-5'>{completedTasks}/2 erledigt</p>
        <Link to={expired? '#' : '/prediction'} className={`p-5 ${expired ? 'ui-black' : user?.prediction.submitted ? 'ui-highlight' : 'ui-fg'} flex gap-3 items-center h-[112px]`}>
          <div>
            <h3 className='mb-1'> "Punktestand" </h3>
            {expired ? (
              <p>Diese Challenge ist vorbei. Erfülle die andere Challenge, um deine Prämie zu bekommen.</p>
            ) : (
              <p>Tippe auf den finalen Punktestand von Ratiopharm Ulm. Ist dieser richtig, gewinnst du einen zusätzlichen Preis.</p>
            )}
          </div>

          <span className='ms-auto p-3 w-[54px] h-[54px] grid place-items-center flex-shrink-0'>
            {expired ? (
              <svg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M15 28C22.1799 28 28 22.1799 28 15C28 7.8201 22.1799 2 15 2C7.8201 2 2 7.8201 2 15C2 22.1799 7.8201 28 15 28Z'
                  stroke='#EC7404'
                  strokeWidth='3'
                  strokeLinejoin='round'
                />
                <path
                  d='M15.0052 8V15.8065L20.5166 21.3185'
                  stroke='#EC7404'
                  strokeWidth='3'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
            ) : user?.prediction.submitted ? (
              <svg width='26' height='20' viewBox='0 0 26 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M25.5575 2.69694L9.56227 19.5357C9.42295 19.6829 9.25741 19.7996 9.07514 19.8793C8.89287 19.959 8.69744 20 8.50008 20C8.30272 20 8.1073 19.959 7.92502 19.8793C7.74275 19.7996 7.57721 19.6829 7.4379 19.5357L0.439971 12.1687C0.300483 12.0219 0.189835 11.8476 0.114345 11.6557C0.0388544 11.4638 2.07854e-09 11.2582 0 11.0505C-2.07853e-09 10.8429 0.0388544 10.6372 0.114345 10.4454C0.189835 10.2535 0.300483 10.0792 0.439971 9.93234C0.57946 9.7855 0.745056 9.66901 0.927306 9.58954C1.10956 9.51007 1.30489 9.46916 1.50216 9.46916C1.69942 9.46916 1.89476 9.51007 2.07701 9.58954C2.25926 9.66901 2.42485 9.7855 2.56434 9.93234L8.50133 16.1824L23.4357 0.463173C23.7174 0.166608 24.0994 0 24.4978 0C24.8962 0 25.2783 0.166608 25.56 0.463173C25.8417 0.759737 26 1.16197 26 1.58137C26 2.00078 25.8417 2.403 25.56 2.69957L25.5575 2.69694Z'
                  fill='#EC7404'
                />
              </svg>
            ) : (
              <svg width='14' height='24' viewBox='0 0 14 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M2.72297 23.5251L13.3932 12.8865C13.5198 12.7599 13.6097 12.6227 13.6629 12.4749C13.7161 12.3272 13.7423 12.1689 13.7414 12C13.7414 11.8311 13.7153 11.6728 13.6629 11.5251C13.6106 11.3773 13.5206 11.2401 13.3932 11.1135L2.72297 0.443272C2.42745 0.147757 2.05806 0 1.61479 0C1.17151 0 0.791568 0.158311 0.474945 0.474934C0.158322 0.791557 1.14441e-05 1.16095 1.14441e-05 1.58311C1.14441e-05 2.00528 0.158322 2.37467 0.474945 2.69129L9.78365 12L0.474945 21.3087C0.179431 21.6042 0.0316725 21.9685 0.0316725 22.4017C0.0316725 22.8348 0.189984 23.2093 0.506607 23.5251C0.82323 23.8417 1.19262 24 1.61479 24C2.03695 24 2.40634 23.8417 2.72297 23.5251Z'
                  fill='#F8F8F8'
                />
              </svg>
            )}
          </span>
        </Link>
        <Link to='/reward-list' className={`p-5 ${user?.rewards.submitted ? 'ui-highlight' : 'ui-fg'} flex gap-3 items-center h-[112px]`}>
          <div>
            <h3 className='mb-1'> "Gib mir mehr" </h3>
            <p>Wähle aus den dargestellten Vorteilen die vier Optionen aus, die dich am meisten ansprechen.</p>
          </div>
          <span className='ms-auto p-3 w-[54px] h-[54px] grid place-items-center flex-shrink-0'>
            {user?.rewards.submitted ? (
              <svg width='26' height='20' viewBox='0 0 26 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M25.5575 2.69694L9.56227 19.5357C9.42295 19.6829 9.25741 19.7996 9.07514 19.8793C8.89287 19.959 8.69744 20 8.50008 20C8.30272 20 8.1073 19.959 7.92502 19.8793C7.74275 19.7996 7.57721 19.6829 7.4379 19.5357L0.439971 12.1687C0.300483 12.0219 0.189835 11.8476 0.114345 11.6557C0.0388544 11.4638 2.07854e-09 11.2582 0 11.0505C-2.07853e-09 10.8429 0.0388544 10.6372 0.114345 10.4454C0.189835 10.2535 0.300483 10.0792 0.439971 9.93234C0.57946 9.7855 0.745056 9.66901 0.927306 9.58954C1.10956 9.51007 1.30489 9.46916 1.50216 9.46916C1.69942 9.46916 1.89476 9.51007 2.07701 9.58954C2.25926 9.66901 2.42485 9.7855 2.56434 9.93234L8.50133 16.1824L23.4357 0.463173C23.7174 0.166608 24.0994 0 24.4978 0C24.8962 0 25.2783 0.166608 25.56 0.463173C25.8417 0.759737 26 1.16197 26 1.58137C26 2.00078 25.8417 2.403 25.56 2.69957L25.5575 2.69694Z'
                  fill='#EC7404'
                />
              </svg>
            ) : (
              <svg width='14' height='24' viewBox='0 0 14 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M2.72297 23.5251L13.3932 12.8865C13.5198 12.7599 13.6097 12.6227 13.6629 12.4749C13.7161 12.3272 13.7423 12.1689 13.7414 12C13.7414 11.8311 13.7153 11.6728 13.6629 11.5251C13.6106 11.3773 13.5206 11.2401 13.3932 11.1135L2.72297 0.443272C2.42745 0.147757 2.05806 0 1.61479 0C1.17151 0 0.791568 0.158311 0.474945 0.474934C0.158322 0.791557 1.14441e-05 1.16095 1.14441e-05 1.58311C1.14441e-05 2.00528 0.158322 2.37467 0.474945 2.69129L9.78365 12L0.474945 21.3087C0.179431 21.6042 0.0316725 21.9685 0.0316725 22.4017C0.0316725 22.8348 0.189984 23.2093 0.506607 23.5251C0.82323 23.8417 1.19262 24 1.61479 24C2.03695 24 2.40634 23.8417 2.72297 23.5251Z'
                  fill='#F8F8F8'
                />
              </svg>
            )}
          </span>
        </Link>
        <h3 className='primary-font text-center p-3'>Erledige alle Aufgaben, um zu gewinnen</h3>
        <div className='ui-fg p-5'>
          <h3>20% Rabatt auf ein Ticket für die nächste Saison</h3>
          <div className='flex gap-3 mt-3'>
            <p>20% Rabatt auf eine Eintrittskarte in der nächsten Saison für eine Einzelperson.</p>
            <img src='ticket-large.png' width='151' height='107' alt='ticket groß' />
          </div>
        </div>
      </main>
      <Footer disabled={!(completedTasks === 2 || (completedTasks === 1 && expired)) } onClick={() => navigate('/claim')} />
    </>
  );
}

export {};
