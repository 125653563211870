import { Reward } from '../types/reward';

type RewardItemProps = {
  item: Reward;
  setPriority: (index: number) => void;
  removePriority: (index: number) => void;
};

export default function RewardItem({ item, setPriority, removePriority }: RewardItemProps) {
  return (
    <article
      className={`flex justify-between gap-3 text-start ui-fg ${item.priority > 0 ? 'selected' : ''}`}
      onClick={() => item.priority > 0 ? removePriority(item.index) : setPriority(item.index)}>
      <div className='py-3 px-5'>
        <h3> {item.title} </h3>
        <p> {item.description}</p>
      </div>
      <div className='reward-image height-full'>
        {item.priority > 0 && <span className='z-50'>{item.priority}</span>}
        <img src={`/${item.image}`} width='88' height='81' alt={`${item.title} Bild`} className={`${item.priority > 0 ?'opacity-50':''}`}></img>
        {item.priority > 0 && <div className='bg-[#EC7404] w-full h-full z-10 mix-blend-multiply'></div>}
      </div>
    </article>
  );
}

export {};
