import ReactDOM from 'react-dom/client';
import './index.css';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Home from './pages/home';
import Prediction from './pages/prediction';
import RewardList from './pages/reward-list';
import Claim from './pages/claim';
import Completed from './pages/completed';
import Help from './pages/help';
import Terms from './pages/terms';
import Landing from './pages/landing';
import UserProvider from './components/UserProvider';
import VerifyEmail from './pages/verify';

const router = createBrowserRouter([
  {
    element: <UserProvider />,
    children: [
      {
        path: '/',
        element: <Landing />,
      },
      {
        path: 'home',
        element: <Home />,
      },
      {
        path: 'prediction',
        element: <Prediction />,
      },
      {
        path: 'reward-list',
        element: <RewardList />,
      },
      {
        path: 'claim',
        element: <Claim />,
      },
      {
        path: '/completed',
        element: <Completed />,
      },
      {
        path: 'verify',
        element: <VerifyEmail />,
      },
      {
        path: 'help',
        element: <Help />,
      },
      {
        path: 'terms',
        element: <Terms />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <section className='app-container'>
    <RouterProvider router={router} />
  </section>
);
