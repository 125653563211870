import { useNavigate } from 'react-router-dom';
import Footer from '../components/Footer';

export default function Landing() {
  const navigate = useNavigate();
  return (
    <>
      <div></div>
      <main className='intro-container'>
        <h3 className='text-center'>Challenges erfüllen. Prämien sichern. Ganz Einfach.</h3>
        <picture className="mt-auto w-[80%]">
          <source type='image/webp' srcSet='ulmx8020.webp' />
          <source type='image/png' srcSet='ulmx8020.png' />
          <img src='ulmx8020.png' alt='UlmX8020' />
        </picture>
      </main>
      <Footer onClick={() => navigate('/home')} label="Let's go" />
    </>
  );
}
