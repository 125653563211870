import Header from '../components/Header';

export default function Expired() {
  return <>
    <Header title='Challenge beendet'/>
    <main className='flex flex-col gap-1 items-center text-center flex-grow py-8'>
      <p className='text-center p-7'> Diese Challenge wurde beendet. Bleib gespannt auf weitere Aktionen in Zukunft</p>
      <img src='ulmx8020.png' alt="Ulm X 8020" className="mt-auto w-[80%]"></img>
    </main>
  </>
}
