import { Outlet } from 'react-router-dom';
import { TimeContext } from '../contexts/time-context';
import { UserContext } from '../contexts/user-context';
import { useTime } from '../hooks/useTime';
import useUser from '../hooks/useUser';
import Expired from '../pages/expired';

export default function UserProvider() {
  const { user, patchUser, setUser } = useUser();
  const { isHalfTime, isFinalTime, isLoading} = useTime();

  if(!isLoading && isFinalTime()) {
    return <Expired></Expired>
  }
  
  return (
    <UserContext.Provider value={{ user, patchUser, setUser }}>
      <TimeContext.Provider value={{isHalfTime, isFinalTime, isLoading}}>
        <Outlet />
      </TimeContext.Provider>
      
    </UserContext.Provider>
  );
}
