import Header from '../components/Header';

export default function Completed() {
  return (
    <>
      <Header title="GLÜCKWUNSCH!!" description="Eine E-Mail an dich ist unterwegs. Danke dir für das mitmachen!"/>
      <main className='flex flex-col gap-1 items-center text-center flex-grow py-8'>
        <img src='success.png' alt="Erfolg Haken" className="my-auto" width="154" height="154"></img>
        <img src='ulmx8020.png' alt="Ulm X 8020" className=' w-[80%]'></img>
      </main>
    </>
  );
}

export {};
