import { doc, getDoc } from 'firebase/firestore';
import { useEffect, useState } from 'react'
import { firestore } from '../Firebase';

export const useTime = () => {
  const [halfTime, setHalfTime] = useState<number | null>(null);
  const [finalTime, setFinalTime] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const getConfig = async () => {
      try {
        const userConfigRef = doc(firestore, 'configs', 'matchtimes');
        const data = await getDoc(userConfigRef);
        setHalfTime(data.get('halftime').seconds);
        setFinalTime(data.get('endtime').seconds);
      } catch(err) {
        console.log(err);
      } finally {
        setIsLoading(false);
      }
    }
    getConfig();
  }, []);

  const isHalfTime = () => {
    return (halfTime ?? 0) < new Date().getTime() / 1000
  }
  const isFinalTime = () => {
    return (finalTime ?? 0) < new Date().getTime() / 1000
  }

  return {isHalfTime, isFinalTime, isLoading};
}
